import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import StatusTableItem from "@/entity/aftersale/ticket/StatusTableItem";
import API from "@/api/aftersale/ticket/status";

@Options({
    name: "app-aftersale-ticket-status",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class Status extends BaseTableMixins<StatusTableItem> implements Partial<BaseTableInterface<StatusTableItem>> {
    public created(): void {
        this.setInterface(this);
        // this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new StatusTableItem();
        this.editModalItem = new StatusTableItem();
    }
    public activated(): void {
        this.getList(1);
    }
    //后端API
    public getApi(): any {
        return API;
    }
    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new StatusTableItem();
        this.editModalItem = new StatusTableItem();
        this.editModalItem.statusStatus = 1;
        this.editModalTitle = "添加工单状态";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.statusName)) return "工单状态名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.statusStatus)) return "工单状态不能为空！";
        return null;
    }
    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改工单状态 (" + this.editModalItem.statusName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.statusId)) return "工单状态ID不能为空！";
        return this.addSubmitChecking();
    }
    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除工单状态",
            message: "工单状态: " + this.editModalItem.statusName + "<br>请确认删除？",
        };
    }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.statusId)) return "工单状态ID不能为空！";
        return null;
    }
    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "工单状态",
            message: "工单状态: " + this.editModalItem.statusName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.statusStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.statusStatus = this.editModalItem.statusStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.statusId)) return "工单状态ID不能为空！";
        return null;
    }
    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "工单状态排序";
    }
}
