export default class StatusTableItem {
    statusId = 0;
    statusStatus = 0;
    statusSortId = 0;
    statusName = "";
    statusRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
